import React, { useState, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import axiosInstance from '../axiosConfig';

function LeaveStatus() {
  const [responses, setResponses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5); // Adjust this value as needed
  const employeeId = localStorage.getItem('EmpId');
  const userRole = localStorage.getItem('UserRole');

  // Fetch responses from API
  useEffect(() => {
    const fetchResponses = async () => {
      try {
        const response = await axiosInstance.get(`hrmsapplication/leave/requests?employeeId=${employeeId}&page=${page}&size=40`);
        setResponses(response.data);
      } catch (error) {
        console.error("Error fetching responses:", error);
      }
    };
    fetchResponses();
  }, [employeeId]);

  // Reset page when searchTerm changes
  useEffect(() => {
    setPage(0);
  }, [searchTerm]);

  // Determine the link based on user role
  const previousPageLink = userRole === 'ROLE_EMPLOYEE' 
    ? '/ApprovalMaster' 
    : `/LeaveApproval/${employeeId}/${userRole}`;

  // Search and filter logic applied to all responses
  const filteredResponses = responses.filter(req => {
    const searchText = searchTerm.toLowerCase();
    return (
      req.employeeId?.toString().includes(searchText) ||
      req.employeeName?.toLowerCase().includes(searchText) ||
      req.leaveName?.toLowerCase().includes(searchText) ||
      req.startDate?.includes(searchText) ||
      req.endDate?.includes(searchText) ||
      req.appliedDate?.includes(searchText) ||
      req.status?.toLowerCase().includes(searchText) ||
      req.comments?.toLowerCase().includes(searchText)
    );
  });

  // Calculate total pages based on filtered responses
  const totalPages = Math.ceil(filteredResponses.length / rowsPerPage);
  const paginatedResponses = filteredResponses.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage(prev => prev - 1);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages - 1) {
      setPage(prev => prev + 1);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <NavLink 
          to={previousPageLink} 
          className="flex items-center justify-start px-4 py-2 border rounded-md bg-gray-200 hover:bg-gray-300 transition"
        >
          <span className="font-semibold text-orange-500">Previous Page</span>
        </NavLink>
        <button className="flex items-center justify-center px-4 py-2 border rounded-md bg-gray-200 hover:bg-gray-300 transition">
          <span className="font-semibold text-orange-500">Export</span>
        </button>
      </div>

      <div className='mt-5'>
        <div className='border rounded-lg shadow-md p-4 bg-white'>
          <div className="flex justify-between items-center mb-4">
            <h1 className="font-bold text-xl">Leaves Status</h1>
            <div className="relative">
              <FaSearch className="absolute right-2 top-2 text-gray-500" />
              <input
                type="text"
                placeholder="Search all fields..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="border border-gray-600 p-1 rounded-lg shadow focus:outline-none focus:ring-2 focus:ring-orange-500 transition duration-200 w-40"
              />
            </div>
          </div>

          {paginatedResponses.length > 0 ? (
            <>
              <table className="w-full table-auto border-collapse">
                <thead>
                  <tr className="bg-gray-300">
                    <th className="border border-solid border-gray-400 p-1 text-center">Employee ID</th>
                    <th className="border border-solid border-gray-400 p-1 text-center">Employee Name</th>
                    <th className="border border-solid border-gray-400 p-1 text-center">Leave Type</th>
                    <th className="border border-solid border-gray-400 p-1 text-center">Start Date</th>
                    <th className="border border-solid border-gray-400 p-1 text-center">End Date</th>
                    <th className="border border-solid border-gray-400 p-1 text-center">Applied Date</th>
                    <th className="border border-solid border-gray-400 p-1 text-center">Status</th>
                    <th className="border border-solid border-gray-400 p-1 text-center">Comments</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedResponses.map(req => (
                    <tr key={req.id} className="text-center">
                      <td className="border border-solid border-gray-400 p-1">{req.employeeId}</td>
                      <td className="border border-solid border-gray-400 p-1">{req.employeeName}</td>
                      <td className="border border-solid border-gray-400 p-1">{req.leaveName}</td>
                      <td className="border border-solid border-gray-400 p-1">{req.startDate}</td>
                      <td className="border border-solid border-gray-400 p-1">{req.endDate}</td>
                      <td className="border border-solid border-gray-400 p-1">{req.appliedDate}</td>
                      <td className="border border-solid border-gray-400 p-1">{req.status}</td>
                      <td className="border border-solid border-gray-400 p-1">{req.comments}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="flex items-center justify-center mt-4">
                <span
                  onClick={handlePreviousPage}
                  className={`cursor-pointer ${page === 0 ? 'text-gray-400' : 'text-gray-600 hover:text-gray-600'} text-lg font-bold mr-2`}
                >
                  &lt;
                </span>
                <span className="font-semibold text-sm">
                  Page {page + 1} of {totalPages}
                </span>
                <span
                  onClick={handleNextPage}
                  className={`cursor-pointer ${page === totalPages - 1 ? 'text-gray-400' : 'text-gray-600 hover:text-gray-600'} text-lg font-bold ml-2`}
                >
                  &gt;
                </span>
              </div>
            </>
          ) : (
            <p className="text-center mt-4">No results found.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default LeaveStatus;
