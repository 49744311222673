import React, { useState, useEffect } from 'react';
import { MdCancelPresentation } from 'react-icons/md';
import { AiOutlineHome } from "react-icons/ai";
import { NavLink } from 'react-router-dom';
import { FaLessThan } from 'react-icons/fa';
import axiosInstance from '../axiosConfig';

const defaultFields = [
  { name: 'employeeId', type: 'text', required: true, min: 1, max: 20, pattern: /^[^\s][a-zA-Z0-9]*$/ },
  { name: 'employeeName', type: 'text', required: true, min: 1, max: 20 },
  { name: 'employeeDesignation', type: 'text', required: true, min: 1, max: 20, pattern: /^[^\s][a-zA-Z0-9\-/\s]*$/ },
  { name: 'projectCode', type: 'text', required: true, min: 1, max: 20, pattern: /^[^\s][a-zA-Z0-9]*$/ },
  { name: 'startDate', type: 'date', required: true },
  { name: 'endDate', type: 'date', required: true },
  { name: 'shiftStartTime', type: 'time', required: true },
  { name: 'shiftEndTime', type: 'time', required: true },
  { name: 'comments', type: 'textarea', required: false, min: 1, max: 100, pattern: /^[^\s].*$/ },
];

const labels = {
  employeeId: 'Employee ID',
  employeeName: 'Employee Name',
  employeeDesignation: 'Designation',
  projectCode: 'Project Code',
  startDate: 'Start Date',
  endDate: 'End Date',
  shiftStartTime: 'Shift Start Time',
  shiftEndTime: 'Shift End Time',
  comments: 'Comments',
};

const Assignments = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [fields, setFields] = useState([]);
  const [rows, setRows] = useState([]);
  const [tempFormData, setTempFormData] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const EmployeeId = localStorage.getItem('EmpId'); // Retrieve user role from local storage


  useEffect(() => {
    const fetchAssignments = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(`hrmsapplication/assignments/getAssignments?employeeId=${EmployeeId}`); // Replace with your actual API endpoint
        setRows(response.data); // Adjust based on the structure of your response
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAssignments();

    const fieldsParam = new URLSearchParams(window.location.search).get('fields');
    if (fieldsParam) {
      const selectedFieldNames = fieldsParam.split(',');
      const newSelectedFields = defaultFields.filter(field => selectedFieldNames.includes(field.name));
      setSelectedFields(newSelectedFields);
      setFields(newSelectedFields);
    }
  }, []);

  const handleModalToggle = () => setIsOpen(prev => !prev);

  const validateFields = () => {
    const newErrors = {};
    fields.forEach((field, index) => {
      const value = tempFormData[index];
      if (field.required && (!value || value.length < field.min || value.length > field.max)) {
        newErrors[field.name] = `min ${field.min} and max ${field.max} characters.`;
      }

      if (field.pattern && !field.pattern.test(value)) {
        newErrors[field.name] = `${field.name} is in an invalid format.`;
      }

      if (field.name === 'endDate' && newErrors['startDate'] === undefined) {
        const startDate = new Date(tempFormData[fields.findIndex(f => f.name === 'startDate')]);
        const endDate = new Date(value);
        if (startDate >= endDate) {
          newErrors['endDate'] = 'End Date must be after Start Date.';
        }
      }
    });
    return Object.keys(newErrors).length === 0;
  };

  const handleRowDelete = (rowIndex) => {
    console.log("Row deleted:", rows[rowIndex]);
    setRows(prev => prev.filter((_, index) => index !== rowIndex));
  };

  const handleFieldSelect = (field) => {
    const isSelected = selectedFields.find(f => f.name === field.name);
    const newSelectedFields = isSelected
      ? selectedFields.filter(f => f.name !== field.name)
      : [...selectedFields, field];

    setSelectedFields(newSelectedFields);
  };

  const handleSaveSelectedFields = () => {
    setFields(selectedFields);
    handleModalToggle();

    const fieldsParam = selectedFields.map(field => field.name).join(',');
    const newUrl = `${window.location.pathname}?fields=${fieldsParam}`;
    window.history.replaceState({}, '', newUrl);
  };

  const handleModalOpen = () => {
    setSelectedFields(fields);
    handleModalToggle();
  };

  const preventInput = (e) => {
    if (e.key !== 'Tab') {
      e.preventDefault();
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="p-6">
      <div>
        <NavLink className="flex items-center justify-start px-2 py-2 overflow-x-auto border border-gray-800 rounded-md w-40 ml-5 mb-5 mt-5" to='/userdashboard'>
          <FaLessThan className="text-orange-500 mr-" />
          <button><span className="text font-semibold text-orange-500">Previous Page</span></button>
        </NavLink>
      </div>
      <NavLink className='border border-black p-2 rounded mb-4 flex items-center' to='/userdashboard'>
        <AiOutlineHome /><span className='pl-1'>Home</span>
      </NavLink>
      <div className="mb-4 flex justify-end">
        <button onClick={handleModalOpen} className="text-black bg-gray-300 font-semibold py-2 px-4 rounded border border-black">
          Add Field
        </button>
        <button className="text-black bg-gray-300 font-semibold py-2 px-4 mx-2 rounded border border-black">
          Export to Excel
        </button>
      </div>
      <div className='overflow-auto'>
        <table className="table-auto border-collapse border border-black w-full">
          <thead>
            <tr className="bg-gray-200">
              {fields.map((field, index) => (
                <th key={index} className="border border-black p-2 text-center">
                  {labels[field.name]}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {fields.map((field) => (
                  <td key={field.name} className="border border-black p-2 text-center">
                    {row[field.name] || ''}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className='bg-orange-500 flex justify-between px-4 py-2 rounded-lg border border-black mb-4'>
              <h2 className="text-lg font-semibold text-center">Select Fields</h2>
              <button onClick={handleModalToggle} className="text-xl font-bold text-black">
                <MdCancelPresentation />
              </button>
            </div>
            <div className="grid grid-cols-2 gap-2">
              {defaultFields.map((field, index) => (
                <button 
                  key={index} 
                  onClick={() => handleFieldSelect(field)} 
                  className={`py-2 px-4 rounded ${selectedFields.find(f => f.name === field.name) ? 'bg-orange-500 text-white' : 'bg-gray-300 text-black'}`}
                >
                  {labels[field.name]}
                </button>
              ))}
            </div>
            <div className="flex justify-end mt-4">
              <button onClick={handleSaveSelectedFields} className="bg-gray-300 hover:bg-gray-400 text-black font-semibold py-2 px-4 rounded-md mr-2">
                Save
              </button>
              <button onClick={handleModalToggle} className="bg-gray-300 hover:bg-gray-400 text-black font-semibold py-2 px-4 rounded-md">
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Assignments;
    