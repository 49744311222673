import React, { useState, useEffect } from "react";
import { ImCross } from "react-icons/im";
import { FaCheck, FaEye, FaEyeSlash } from "react-icons/fa";
import axios from 'axios';

const ForgotPassword = () => {
  const [employeeId, setEmployeeId] = useState("");
  const [createPassword, setCreatePassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [showCreatePassword, setShowCreatePassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [otp, setOtp] = useState(false);
  const [timer, setTimer] = useState(300); // Timer set to 5 minutes in seconds
  const [canResendOtp, setCanResendOtp] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const togglePasswordVisibility = () => {
    setShowCreatePassword(!showCreatePassword);
  };

  const togglePassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    let countdown;
    if (otp && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(countdown);
      setCanResendOtp(true);
    }
    return () => clearInterval(countdown);
  }, [otp, timer]);

  // Format timer as mm:ss
  const formatTimer = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleVerify = async () => {
    if (!employeeId) {
      setErrors({ employeeId: "Input cannot be empty." });
    } else {
      setErrors({});
      try {
        const response = await axios.post(`https://hrms-application-oxy0.onrender.com/hrmsapplication/authentication/forgot-password`, {
          employeeId: employeeId,
        });
        
        setOtp(true);
        setTimer(300); // Reset timer to 5 minutes
        setCanResendOtp(false);
      } catch (error) {
        setErrors({ employeeId: "Failed to send employee ID. Please try again." });
      }
    }
  };

  const handleResendOtp = () => {
    setTimer(300); // Reset timer to 5 minutes
    setCanResendOtp(false);
  };

  const handleOtpVerify = () => {
    if (otpValue.length !== 6) {
      setErrors({ otp: "OTP is required and must be 6 digits" });
    } else {
      setErrors({});
      setOtp(false);
      setShowMessage(true);
    }
  };

  const handleChangePassword = async () => {
    if (createPassword !== confirmPassword || createPassword.length < 8) {
      setErrors({ confirmPassword: "Passwords do not match or are too short" });
    } else {
      try {
        await axios.patch(`https://hrms-application-oxy0.onrender.com/hrmsapplication/authentication/resetPassword`, {
          employeeId: employeeId,
          otp: otpValue,
          password: createPassword,
        });

        setShowSuccessPopup(true);
        setCreatePassword("");
        setConfirmPassword("");
      } catch (error) {
        setErrors({ resetPassword: "Failed to reset password. Please try again." });
      }
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-neutral-100">
      <div className="bg-white p-10 rounded-3xl shadow-lg w-full max-w-md mt-0">
      <img
            src='https://media.licdn.com/dms/image/D560BAQFQiuOx34RWww/company-logo_200_200/0/1720769688540?e=1730937600&v=beta&t=Tfi0cXQ9shhTzXWAwIelpY6x01GJos3L6Fh-mfhTbBg'
            alt='Logo'
            className='w-50 mb-0 mx-auto'
          />
        <h1 className="text-4xl font-bold mb-6 text-center text-orange-600">Forgot Password</h1>
        <form className="space-y-4">
            
          {/* {/ Employee ID Input /} */}
          <div>
            <label className="block text-sm font-bold mb-2 text-left">Employee ID</label>
            <div className="flex items-center space-x-2">
              <input
                type="text"
                placeholder="Employee ID"
                value={employeeId}
                onChange={(e) => setEmployeeId(e.target.value)}
                className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              />
              <button type="button" onClick={handleVerify} className="bg-orange-600 text-white py-2 px-4 rounded-2xl h-[40px] w-[70px] focus:outline-none">
                Verify
              </button>
            </div>
            {errors.employeeId && <p className="text-red-500 text-sm mt-1">{errors.employeeId}</p>}
          </div>

          {/* {/ OTP Input /} */}
          {otp && (
            <div>
              <label className="block text-sm font-medium text-gray-700">OTP</label>
              <div className="flex space-x-2">
                <input
                  type="text"
                  value={otpValue}
                  onChange={(e) => setOtpValue(e.target.value)}
                  maxLength="6"
                  className="w-[50%] h-[50px] outline-none rounded-xl bg-gray-300 text-white text-center"
                />
                <button type="button" onClick={handleOtpVerify} className="bg-orange-600 text-white py-2 px-4 rounded-lg focus:outline-none">
                  Verify OTP
                </button>
              </div>
              {errors.otp && <p className="text-red-500 text-sm mt-1">{errors.otp}</p>}
              {timer > 0 ? (
                <p className="text-sm bg-orange-600 mt-2">Resend OTP in {formatTimer()}</p>
              ) : (
                <button
                  type="button"
                  onClick={handleResendOtp}
                  className="text-sm bg-orange-600 mt-2 focus:outline-none"
                  disabled={!canResendOtp}
                >
                  Resend OTP
                </button>
              )}
            </div>
          )}

          {/* {/ New Password Input /} */}
          {showMessage && (
            <>
              <div>
                <label className="block text-sm font-medium text-gray-700">Create New Password</label>
                <div className="flex space-x-2">
                  <input
                    type={showCreatePassword ? "text" : "password"}
                    placeholder="Create Password"
                    value={createPassword}
                    onChange={(e) => setCreatePassword(e.target.value)}
                    className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-orange-600"
                  />
                  <button type="button" onClick={togglePasswordVisibility} className="text-gray-500 focus:outline-none">
                    {showCreatePassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
                {errors.createPassword && <p className="text-red-500 text-sm mt-1">{errors.createPassword}</p>}
              </div>

              {/* {/ Confirm Password Input /} */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Confirm New Password</label>
                <div className="flex space-x-2">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-orange-600"
                  />
                  <button type="button" onClick={togglePassword} className="text-gray-500 focus:outline-none">
                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
                {errors.confirmPassword && <p className="text-red-500 text-sm mt-1">{errors.confirmPassword}</p>}
              </div>

              {/* {/ Reset Password Button /} */}
              <button type="button" onClick={handleChangePassword} className="bg-orange-600 text-white py-2 px-4 rounded-lg w-full focus:outline-none">
                Reset Password
              </button>
            </>
          )}
        </form>

        {/* {/ Success Popup /} */}
        {showSuccessPopup && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <button className="absolute top-0 right-0 mt-2 mr-2 text-gray-500 focus:outline-none" onClick={() => setShowSuccessPopup(false)}>
                <ImCross />
              </button>
              <h3 className="text-xl font-bold mb-4">Password Reset Successful!</h3>
              <p>Your password has been reset successfully. You can now log in with your new password.</p>
              <button className="bg-orange-600 text-white py-2 px-4 rounded-lg mt-4 focus:outline-none" onClick={() => setShowSuccessPopup(false)}>
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
