import React, { useState, useEffect } from "react";
import axiosInstance from './axiosConfig';
import { useParams } from "react-router-dom";

const Organisation = () => {
  const [employees, setEmployees] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { employeeId } = useParams();
  const userRole = localStorage.getItem("UserRole");
  const accessToken = localStorage.getItem("AccessToken"); 
  const loggedInUserId = localStorage.getItem("UserId");

  // Define role hierarchy to rank designations
  const roleHierarchy = {
    CEO: 1,
    "Vice President": 2,
    Director: 3,
    Manager: 4,
    Lead: 5,
    "Senior Developer": 6,
    Developer: 7,
    Intern: 8,
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        let response;

        if (['ROLE_HR', 'ROLE_ADMIN', 'ROLE_EMPLOYEE', 'ROLE_MANAGER'].includes(userRole)) {
          response = await axiosInstance.get(`hrmsapplication/organization/?employeeId=${employeeId}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`, 
            },
          });
        } else {
          return;
        }

        const data = response.data;

        // Sort employees based on their designation ranks
        const formattedEmployees = data
          .map(emp => ({
            employeeId: emp.employeeId,
            employeeName: emp.employeeName,
            designation: emp.designation,
            type: emp.type,
            reportingManagerId: emp.reportingManagerId,
            reportingManagerName: emp.reportingManagerName,
            reports: emp.reports || [], 
          }))
          .sort((a, b) => (roleHierarchy[a.designation] || 100) - (roleHierarchy[b.designation] || 100));

        setEmployees(formattedEmployees);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching employees:", error);
        setIsLoading(false);
      }
    };

    fetchEmployees();
  }, [userRole, employeeId, accessToken, loggedInUserId]);

  const handleClick = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const getSuperior = (employeeId) => {
    const employee = employees.find(emp => emp.employeeId === employeeId);
    return employee && employee.reportingManagerId
      ? employees.find(emp => emp.employeeId === employee.reportingManagerId)
      : null;
  };

  const getSubordinates = (employeeId) => {
    return employees.filter(emp => emp.reportingManagerId === employeeId);
  };

  return (
    <div className="w-full h-full bg-gray-100 p-4">
      <h2 className="text-lg font-semibold my-4">Organisation Chart</h2>
      <div className="flex flex-col items-center">
        {isLoading ? (
          <p>Loading...</p>
        ) : employees.length === 0 ? (
          <p>No Data Available</p>
        ) : (
          employees.map((employee, index) => (
            <div
              key={employee.employeeId}
              className={`w-full flex flex-col items-center transition-all duration-300 ease-in-out ${
                expandedIndex !== null && expandedIndex !== index && index > expandedIndex
                  ? "opacity-5 pointer-events-none"
                  : ""
              }`}
            >
              <div
                className={`relative flex flex-col items-center bg-white p-4 shadow-lg mb-4 rounded-lg cursor-pointer transform transition-all duration-300 w-56 h-56 ${
                  expandedIndex === index ? "scale-110" : ""
                }`}
                onClick={() => handleClick(index)}
              >
                <h3 className="font-semibold text-lg text-center">{employee.employeeName}</h3>
                <p className="text-sm text-gray-500 text-center">{employee.designation}</p>
                <p className="text-xs text-gray-500 text-center">Type: {employee.type}</p>
                <p className="text-xs text-gray-500 text-center">Manager: {employee.reportingManagerName}</p>
              </div>

              {/* Expanded View showing Subordinates only for CEO */}
              {expandedIndex === index && (
                <div className="mt-4 w-full flex flex-col items-center ">
                  {/* Superior Card */}
                  {employee.designation !== "CEO" && getSuperior(employee.employeeId) && (
                    <div className="flex flex-col items-center bg-gray-200 p-4 shadow rounded-lg mb-4">
                      <h4 className="font-semibold text-md">Superior:</h4>
                      <p className="text-sm text-gray-700">{getSuperior(employee.employeeId).employeeName}</p>
                      <p className="text-xs text-gray-500">Designation: {getSuperior(employee.employeeId).designation}</p>
                      <p className="text-xs text-gray-500">Type: {getSuperior(employee.employeeId).type}</p>
                      <p className="text-xs text-gray-500">Manager: {getSuperior(employee.employeeId).reportingManagerName}</p>
                    </div>
                  )}

                  {/* Subordinate Cards */}
                  {getSubordinates(employee.employeeId).length > 0 && (
                    <div className="flex justify-center flex-wrap gap-4 max-w-[50%]">
                      {getSubordinates(employee.employeeId).map((subordinate, subIndex) => (
                        <div
                          key={subordinate.employeeId}
                          className="flex flex-col items-center bg-gray-100 p-4 shadow rounded-lg cursor-pointer"
                          style={{ padding: "20px", maxWidth: "150px", flexBasis: "18%" }}
                        >
                          <p className="text-sm text-gray-700 text-center">{subordinate.employeeName}</p>
                          <p className="text-xs text-gray-500 text-center">{subordinate.designation}</p>
                          <p className="text-xs text-gray-500 text-center">Type: {subordinate.type}</p>
                          <p className="text-xs text-gray-500 text-center">Manager: {subordinate.reportingManagerName}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Organisation;