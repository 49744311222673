import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const accessToken = localStorage.getItem('Token'); 

  // If there is no access token, navigate to the login page
  if (!accessToken) {
    return <Navigate to="/" replace />;
  }

  // If access token exists, render the children components
  return children; 
};

export default ProtectedRoute;
