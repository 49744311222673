import React, { useState, useEffect } from "react";
import axiosInstance from "./axiosConfig";

const IdCardPreview = () => {
  const [employeeData, setEmployeeData] = useState(null);
  const employeeId = localStorage.getItem("EmpId"); 

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await axiosInstance.get(
          `hrmsapplication/employee/getIdCard?employeeId=${employeeId}`
        );
        setEmployeeData(response.data);
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    if (employeeId) {
      fetchEmployeeData();
    }
  }, [employeeId]);

  if (!employeeData) {
    return <div>Loading...</div>; // Display a loading state while the data is being fetched
  }

  // Use optional chaining to handle possible null or undefined values
  const {
    employeeName = "N/A", // Provide default values in case of null or undefined
    contactNumber = "N/A",
    designation = "N/A",
   
// Default to an empty object in case officeAddressDTO is null or undefined
  } = employeeData || {}; // Default to an empty object in case employeeData is null or undefined

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-50 to-indigo-100">
      <div className="relative w-80 bg-white rounded-xl shadow-xl p-6">
      
        <button className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 transition duration-200">
          &times;
        </button>

        <h2 className="text-center text-2xl font-semibold text-gray-700 mb-5">ID Card Preview</h2>

        <div className="text-center mb-6">
       
          <img
            src="/rfchh.jpg"
            alt="Company Logo"
            className="mx-auto w-24 h-auto"
          />
       
          <div className="relative w-36 h-36 bg-gray-100 mx-auto mt-4 rounded-full flex items-center justify-center shadow-inner">
            <span className="text-gray-400 text-lg">150 x 150</span>
          </div>
        </div>

   \
        <div className="text-center">
          <h3 className="text-2xl font-bold text-gray-800">{employeeName}</h3>
          <p className="text-base text-indigo-600 font-medium">{designation}</p>

          <div className="mt-4 text-sm text-gray-600 leading-6">
            <p>
              <strong>ID No.:</strong> {employeeId || "N/A"}
            </p>
            <p>
              <strong>Phone:</strong> {contactNumber}
            </p>
            {/* <p>{`${buildingNameFloor}, ${street}, ${village}, ${town}, ${district}, ${state}, ${country}, ${pincode}`}</p> */}
          </div>
        </div>

        <div className="mt-6 flex justify-between space-x-2">
          <button className="bg-green-500 hover:bg-green-600 text-white rounded-md px-4 py-2 w-full font-medium transition duration-200">
            Preview ID Card
          </button>
          <button className="bg-blue-500 hover:bg-blue-600 text-white rounded-md px-4 py-2 w-full font-medium transition duration-200">
            Download ID Card
          </button>
        </div>
      </div>
    </div>
  );
};

export default IdCardPreview;
